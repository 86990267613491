import { Box, BoxProps } from '@chakra-ui/react';
import React from 'react';

type Props = BoxProps;

const Paper = ({ children, ...rest }: Props) => {
  return (
    <Box bg={'white'} borderRadius={'md'} boxShadow={'md'} {...rest}>
      {children}
    </Box>
  );
};

export default Paper;
