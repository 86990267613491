import { TableCellProps, Td as TableCell } from '@chakra-ui/react';
import React from 'react';

export const Td = (props: TableCellProps) => {
  return (
    <TableCell
      _first={{ borderTopLeftRadius: 'md', borderBottomLeftRadius: 'md' }}
      _last={{ borderTopRightRadius: 'md', borderBottomRightRadius: 'md' }}
      {...props}
      py={2}
      px={6}
    />
  );
};
