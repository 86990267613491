import { Skeleton } from '@chakra-ui/react';
import React from 'react';

import { Td } from './Td';

export const SkeletonTd = () => {
  return (
    <Td>
      <Skeleton>Lorem ipsum</Skeleton>
    </Td>
  );
};
