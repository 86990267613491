import { Box, HStack, Stack } from '@chakra-ui/react';
import { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import DrawerMenu from '@/components/ui/Navigation/DrawerMenu';
import { WipPage } from '@/components/ui/Placeholders/WipPage';
import { useAuth } from '@/features/auth';
import { domainsRoute } from '@/features/tenants';
import { usersRoute } from '@/features/users';

const AppWrapper = () => {
  const { isAuth } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuth) {
      navigate('/');
    }
  }, [isAuth, navigate]);
  return (
    <HStack align={'start'} w={'100%'}>
      <DrawerMenu />
      <Stack w={'100%'}>
        <Box h={`100vh`} overflowY={'auto'} p={8}>
          <Outlet />
        </Box>
      </Stack>
    </HStack>
  );
};

export const protectedRoutes = [
  {
    path: '/app',
    element: <AppWrapper />,
    children: [
      { path: '', element: <WipPage title={'Strona startowa'} /> },
      { path: 'users', children: usersRoute },
      { path: 'instances', children: domainsRoute },
    ],
  },
];
