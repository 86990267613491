import { baseApi } from '@/lib';

import { Domain } from '../types';

export const domainsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    domains: builder.query<Array<Domain>, void>({
      query: () => 'domains',
    }),
  }),
});

export const { useDomainsQuery } = domainsApi;
