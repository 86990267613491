import { HStack, Stack } from '@chakra-ui/react';
import React from 'react';

import { Header } from '@/components/ui/Header/Header';
import { Breadcrumbs } from '@/components/ui/Navigation/Breadcrumbs';
import { SearchInput } from '@/components/ui/SearchInput/SearchInput';
import { Breadcrumb } from '@/types/Breadcrumbs';

// import { useDomainsQuery } from '../api/domainsApi';
import { useTenantsQuery } from '../api/tenantsApi';
import { CreateTenant } from '../components/CreateTenant/CreateTenant';
import { DomainsTable } from '../components/TenantsTable/TenantsTable';

const breadcrumbs: Breadcrumb[] = [
  {
    path: '/app/instances',
    displayValue: 'Instancje zarządzające',
  },
];

export const DomainsListPage = () => {
  const { data: tenants } = useTenantsQuery();

  return (
    <Stack>
      <Breadcrumbs breadcrumbs={breadcrumbs} />
      <HStack>
        <Header>Instancje zarządzające {tenants ? `(${tenants.length})` : null}</Header>
        <HStack flexGrow={1} />
        <SearchInput />
        <CreateTenant />
      </HStack>
      <DomainsTable />
    </Stack>
  );
};
