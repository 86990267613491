import {
  Flex,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Tag,
  Text,
} from '@chakra-ui/react';
import React, { Key, useMemo } from 'react';

type Props<T> = {
  values?: Array<T>;
  getLabel: (v: T) => string;
  getKey: (v: T) => Key;
  popoverHeader?: string;
  maxVisibleElements?: number;
};

export const TableCellArrayPopover = <T extends object | string>({
  values,
  getLabel,
  getKey,
  popoverHeader,
  maxVisibleElements = 1,
}: Props<T>) => {
  const visibleElementsNumber = useMemo(() => {
    if (!values || values.length === 0) return 0;
    if (values.length < maxVisibleElements) return values.length;
    return maxVisibleElements;
  }, [maxVisibleElements, values]);

  const firstElements = useMemo(() => {
    if (!values || values.length < 1) return null;
    const arr = values.slice(0, visibleElementsNumber);
    return arr.map((value) => getLabel(value)).join(', ');
  }, [getLabel, values, visibleElementsNumber]);

  const restElementsNumber = useMemo(() => {
    if (!values || values.length === 0) return 0;
    return values.length - 1;
  }, [values]);

  const restElements = useMemo(() => {
    if (!values || values.length === 0) return [];
    return values.slice(visibleElementsNumber);
  }, [values, visibleElementsNumber]);

  if (!values || values.length === 0) return null;

  if (values.length === 1) return <Text>{getLabel(values[0])}</Text>;

  return (
    <Flex alignItems={'center'} gap={2}>
      <Text>{firstElements}</Text>
      {restElementsNumber ? (
        <Popover trigger="hover">
          <PopoverTrigger>
            <Tag>+{restElementsNumber}</Tag>
          </PopoverTrigger>
          <PopoverContent sx={{ margin: 0 }}>
            <PopoverArrow />
            {popoverHeader ? <PopoverHeader>{popoverHeader}</PopoverHeader> : null}
            <PopoverBody>
              {restElements.map((d) => (
                <Text key={getKey(d)}>{getLabel(d)}</Text>
              ))}
            </PopoverBody>
          </PopoverContent>
        </Popover>
      ) : null}
    </Flex>
  );
};
