import { MenuItem } from '@chakra-ui/react';
import React from 'react';
import { MdDelete } from 'react-icons/md';
import { toast } from 'react-toastify';

import { useDeleteUserMutation } from '../../api';

type Props = {
  userId: string;
};

export const DeleteUser = ({ userId }: Props) => {
  const [deleteUser] = useDeleteUserMutation();

  const handleDelete = async () => {
    try {
      await deleteUser({ userId }).unwrap();
      toast.done(`Usunięto użytkownika`);
    } catch (err: any) {
      toast.error(err.data.message);
    }
  };

  return (
    <MenuItem color={'red.500'} icon={<MdDelete size={'20'} />} onClick={handleDelete}>
      Usuń
    </MenuItem>
  );
};
