import { Box, Button, Heading, Stack } from '@chakra-ui/react';
import React from 'react';

import { ReactComponent as EmptyStateIlustration } from '@/assets/empty.svg';

type Props = {
  butonTitle?: string;
  emptyStateMessage?: string;
  onClick?: () => void;
};

export const DomainsEmptyState = ({ emptyStateMessage, butonTitle, onClick }: Props) => {
  return (
    <Box>
      <Stack align={'center'} spacing="4">
        <EmptyStateIlustration height={'350'} />
        <Heading size={'lg'}>{emptyStateMessage || 'Lista jest pusta'}</Heading>
        {butonTitle && onClick ? (
          <Button size={'lg'} colorScheme={'purple'} onClick={onClick}>
            {butonTitle}
          </Button>
        ) : null}
      </Stack>
    </Box>
  );
};
