import { Button, DrawerBody, DrawerFooter, HStack } from '@chakra-ui/react';
import React from 'react';

import { FormSkeleton } from '@/components/form/FormSkeleton';

type Props = {
  onClose: () => void;
};

export const DrawerFormSkeleton = ({ onClose }: Props) => {
  return (
    <>
      <DrawerBody>
        <FormSkeleton />
      </DrawerBody>
      <DrawerFooter>
        <HStack>
          <Button size={'lg'} onClick={onClose}>
            Anuluj
          </Button>
          <Button size={'lg'} colorScheme={'purple'} disabled>
            Zapisz
          </Button>
        </HStack>
      </DrawerFooter>
    </>
  );
};
