import { HStack, Stack } from '@chakra-ui/react';

import { Header } from '@/components/ui/Header/Header';
import { Breadcrumbs } from '@/components/ui/Navigation/Breadcrumbs';
import { SearchInput } from '@/components/ui/SearchInput/SearchInput';
import { Breadcrumb } from '@/types/Breadcrumbs';

import { useUsersQuery } from '../api';
import { CreateUser } from '../components/CreateUser/CreateUser';
import { UsersTable } from '../components/UsersTable/UsersTable';

const breadcrumbs: Breadcrumb[] = [
  {
    path: '/app/users',
    displayValue: 'Użytkownicy',
  },
];

const UsersListPage = () => {
  const { data } = useUsersQuery();
  return (
    <Stack>
      <Breadcrumbs breadcrumbs={breadcrumbs} />
      <HStack>
        <Header>Użytkownicy {data?.results.length ? `(${data?.results.length})` : ''}</Header>
        <HStack flexGrow={'1'} />
        <SearchInput />
        <CreateUser />
      </HStack>
      <UsersTable />
    </Stack>
  );
};

export default UsersListPage;
