import { baseApi } from '@/lib';

import {
  CreateTenantRequestDTO,
  DeleteTenantRequestDTO,
  GetTenantByIdRequestDTO,
  Tenant,
  UpdateTenantRequestDTO,
} from '../types';

const enchancedApi = baseApi.enhanceEndpoints({
  addTagTypes: ['Tenants'],
});

export const tenantsApi = enchancedApi.injectEndpoints({
  endpoints: (builder) => ({
    tenants: builder.query<Array<Tenant>, void>({
      query: () => 'tenants',
      providesTags: (res) =>
        res
          ? [
              ...res.map((t) => ({ type: 'Tenants' as const, id: t.id })),
              { type: 'Tenants', id: 'LIST' },
            ]
          : [{ type: 'Tenants', id: 'LIST' }],
    }),

    tenantById: builder.query<Tenant, GetTenantByIdRequestDTO>({
      query: ({ tenantId }) => `tenants/${tenantId}`,
      providesTags: (res) => (res ? [{ type: 'Tenants', id: res.id }] : []),
    }),
    createTenant: builder.mutation<void, CreateTenantRequestDTO>({
      query: ({ body }) => ({
        url: 'tenants',
        method: 'POST',
        body,
      }),
      invalidatesTags: [{ type: 'Tenants', id: 'LIST' }],
    }),
    updateTenant: builder.mutation<void, UpdateTenantRequestDTO>({
      query: ({ tenantId, body }) => ({
        url: `tenants/${tenantId}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: (_, __, query) => [{ type: 'Tenants', id: query.tenantId }],
    }),
    deleteTenant: builder.mutation<void, DeleteTenantRequestDTO>({
      query: ({ tenantId }) => ({
        url: `tenants/${tenantId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_, __, query) => [{ type: 'Tenants', id: query.tenantId }],
    }),
  }),
});

export const {
  useTenantsQuery,
  useTenantByIdQuery,
  useCreateTenantMutation,
  useUpdateTenantMutation,
  useDeleteTenantMutation,
} = tenantsApi;
