import React from 'react';
import { Control, useController } from 'react-hook-form';
import ReactSelect, { Props as ReactSelectProps } from 'react-select';

import { FormErrorText } from './FormErrorText';

type Props<T> = ReactSelectProps<T> & {
  options: T[];
  control: Control<any>;
  name: string;
};

export const FormReactSelect = <T extends object>({ name, control, ...rest }: Props<T>) => {
  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    control,
  });

  return (
    <>
      <ReactSelect
        closeMenuOnSelect={!rest.isMulti}
        noOptionsMessage={() => 'Brak opcji'}
        {...field}
        {...rest}
      />
      <FormErrorText>{error?.message}</FormErrorText>
    </>
  );
};
