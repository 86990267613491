import { MenuItem, useDisclosure } from '@chakra-ui/react';
import { MdEdit } from 'react-icons/md';

import { DrawerFormWrapper } from '@/components/ui/Drawer/DrawerFormWrapper';

import { EditUserForm } from './EditUserForm';

type Props = {
  userId: string;
};

export const EditUser = ({ userId }: Props) => {
  const { isOpen, onClose, onOpen } = useDisclosure();

  return (
    <>
      <MenuItem icon={<MdEdit size={'20'} />} onClick={onOpen}>
        Edytuj
      </MenuItem>
      <DrawerFormWrapper title={'Edytuj użytkownika'} isOpen={isOpen} onClose={onClose}>
        <EditUserForm userId={userId} onClose={onClose} />
      </DrawerFormWrapper>
    </>
  );
};
