import React from 'react';

import { Table } from '@/components/ui/Table';

import { useUsersQuery } from '../../api';

import { columns } from './Columns';

export const UsersTable = () => {
  const { data, isLoading: isLoadingUsers } = useUsersQuery();
  return <Table columns={columns} data={data?.results || []} isLoading={isLoadingUsers} />;
};
