import { MenuItem } from '@chakra-ui/react';
import { createColumnHelper } from '@tanstack/react-table';
import { MdDelete } from 'react-icons/md';

import { TableActionMenu } from '@/components/ui/Table/TableActionMenu';
import { TableCellArrayPopover } from '@/components/ui/Table/TableCellArrayPopover';

import { Tenant } from '../../types';
import { EditDomain } from '../EditTenant/EditTenant';

const columnHelper = createColumnHelper<Tenant>();

export const columns = [
  columnHelper.accessor('name', {
    header: 'Nazwa',
  }),
  columnHelper.display({
    id: 'assignedDomains',
    header: 'Przypisane placówki',
    cell({ row }) {
      return (
        <TableCellArrayPopover
          values={row.original.relatedDomains}
          getKey={(v) => v.domain}
          getLabel={(v) => v.name}
        />
      );
    },
  }),
  columnHelper.display({
    id: 'actions',
    cell: (row) => (
      <TableActionMenu>
        <EditDomain tenantId={row.row.original.id} />
        <MenuItem color={'red.500'} icon={<MdDelete size={'20'} />}>
          Usuń
        </MenuItem>
      </TableActionMenu>
    ),
  }),
];
