import {
  Drawer,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  MenuItem,
  useDisclosure,
} from '@chakra-ui/react';
import { MdEdit } from 'react-icons/md';

import { EditDomainForm } from './EditTenantForm';

type Props = {
  tenantId: string;
};

export const EditDomain = ({ tenantId }: Props) => {
  const { isOpen, onClose, onOpen } = useDisclosure();

  return (
    <>
      <MenuItem onClick={onOpen} icon={<MdEdit size={'20'} />}>
        Edytuj
      </MenuItem>
      {isOpen ? (
        <Drawer isOpen={isOpen} onClose={onClose} size={'lg'}>
          <DrawerOverlay />
          <DrawerContent>
            <DrawerHeader>Edytuj instancję zarządzającą</DrawerHeader>
            <DrawerCloseButton />
            <EditDomainForm tenantId={tenantId} onClose={onClose} />
          </DrawerContent>
        </Drawer>
      ) : null}
    </>
  );
};
