import { Input, Skeleton, Stack } from '@chakra-ui/react';
import React from 'react';

export const FormSkeleton = () => {
  return (
    <Stack spacing={8}>
      <Stack spacing={4}>
        <Skeleton w={'50%'}>Lorem ipsum</Skeleton>
        <Skeleton>
          <Input />
        </Skeleton>
        <Skeleton>
          <Input />
        </Skeleton>
      </Stack>

      <Stack spacing={4}>
        <Skeleton w={'50%'}>Lorem ipsum</Skeleton>
        <Skeleton>
          <Input />
        </Skeleton>
        <Skeleton>
          <Input />
        </Skeleton>
      </Stack>

      <Stack spacing={4}>
        <Skeleton w={'50%'}>Lorem ipsum</Skeleton>
        <Skeleton>
          <Input />
        </Skeleton>
        <Skeleton>
          <Input />
        </Skeleton>
      </Stack>
    </Stack>
  );
};
