import { Button, useDisclosure } from '@chakra-ui/react';
import React from 'react';

import { DrawerFormWrapper } from '@/components/ui/Drawer/DrawerFormWrapper';

import { CreateTenantForm } from './CreateTenantForm';

export const CreateTenant = () => {
  const { isOpen, onClose, onOpen } = useDisclosure();

  return (
    <>
      <Button colorScheme={'purple'} onClick={onOpen}>
        Utwórz instancję
      </Button>
      <DrawerFormWrapper
        isOpen={isOpen}
        onClose={onClose}
        title={'Utwórz nową instancję zarządzającą'}
      >
        <CreateTenantForm onClose={onClose} />
      </DrawerFormWrapper>
    </>
  );
};
