import { Button, Stack } from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { z } from 'zod';

import FormInput from '@/components/form/FormInput';
import { useAppDispatch } from '@/hooks/store';

import { useLoginMutation } from '../api';
import { authenticationSuccess } from '../stores/authSlice';

type Form = {
  email: string;
  password: string;
};

const schema = z.object({
  email: z.string().min(1, 'Pole jest wymagane').email('Podaj poprawny adres e-mail'),
  password: z.string().min(1, 'Pole jest wymagane'),
});

const INITAL_VALUES: Form = {
  email: 'fake@example.com',
  password: 'password1',
};

const LoginForm = () => {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<Form>({
    resolver: zodResolver(schema),
    defaultValues: INITAL_VALUES,
  });

  const dispatch = useAppDispatch();
  const [login] = useLoginMutation();

  const onSubmit: SubmitHandler<Form> = async (data) => {
    try {
      const { tokens, user } = await login(data).unwrap();
      console.log('submit', data);
      console.log('user', user);
      if (user.role !== 'admin')
        throw {
          data: {
            message: 'Użytkownik nie ma uprawnień administratora',
          },
        };
      const accessToken = tokens.access.token;
      localStorage.setItem('access_token', accessToken);
      dispatch(authenticationSuccess(accessToken));
    } catch (err: any) {
      console.log(err.data);
      toast.error(err.data.message);
    }
  };

  return (
    <Stack w={'full'} as="form" spacing={4} onSubmit={handleSubmit(onSubmit)}>
      <FormInput placeholder="Email" {...register('email')} error={errors.email} />
      <FormInput
        placeholder="Hasło"
        {...register('password')}
        error={errors.password}
        type={'password'}
      />
      <Button colorScheme={'purple'} type={'submit'} isLoading={isSubmitting}>
        Zaloguj się
      </Button>
    </Stack>
  );
};

export default LoginForm;
