import { baseApi } from '@/lib';

import {
  CreateUserRequestDTO,
  MeRequestDTO,
  UpdateUserRequestDTO,
  User,
  UsersResponseDTO,
} from '../types';

const taggedApi = baseApi.enhanceEndpoints({ addTagTypes: ['Users', 'Me'] });

export const usersApi = taggedApi.injectEndpoints({
  endpoints: (builder) => ({
    users: builder.query<UsersResponseDTO, void>({
      query: () => 'users',
      providesTags: (res) =>
        res
          ? [
              { type: 'Users', id: 'List' },
              ...res.results.map((user) => ({ type: 'Users' as const, id: user.id })),
            ]
          : [{ type: 'Users', id: 'List' }],
    }),
    user: builder.query<User, { userId: string }>({
      query: ({ userId }) => `users/${userId}`,
      providesTags: (res) => (res ? [{ type: 'Users', id: res.id }] : []),
    }),
    createUser: builder.mutation<void, CreateUserRequestDTO>({
      query: ({ body }) => ({
        url: 'users',
        method: 'POST',
        body,
      }),
      invalidatesTags: [{ type: 'Users', id: 'List' }],
    }),
    updateUser: builder.mutation<User, UpdateUserRequestDTO>({
      query: ({ userId, body }) => ({
        url: `users/${userId}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: (_, __, query) => [{ type: 'Users', id: query.userId }],
    }),
    deleteUser: builder.mutation<void, { userId: string }>({
      query: ({ userId }) => ({
        url: `users/${userId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_, __, query) => [{ type: 'Users', id: query.userId }],
    }),
    me: builder.query<MeRequestDTO, void>({
      query: () => 'users/me',
      providesTags: [{ type: 'Me' }],
    }),
  }),
});

export const {
  useUsersQuery,
  useUserQuery,
  useUpdateUserMutation,
  useCreateUserMutation,
  useDeleteUserMutation,
  useMeQuery,
} = usersApi;
