import { Input, InputGroup, InputRightElement } from '@chakra-ui/react';
import React from 'react';
import { MdSearch } from 'react-icons/md';

export const SearchInput = () => {
  return (
    <InputGroup maxWidth={'250px'}>
      <Input placeholder="Szukaj" bg={'white'} />
      <InputRightElement>
        <MdSearch />
      </InputRightElement>
    </InputGroup>
  );
};
