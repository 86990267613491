import { Button, useDisclosure } from '@chakra-ui/react';
import React from 'react';

import { DrawerFormWrapper } from '@/components/ui/Drawer/DrawerFormWrapper';

import { CreateUserForm } from './CreateUserForm';

export const CreateUser = () => {
  const { isOpen, onClose, onOpen } = useDisclosure();

  return (
    <>
      <Button colorScheme={'purple'} onClick={onOpen}>
        Utwórz użytkownika
      </Button>
      <DrawerFormWrapper isOpen={isOpen} onClose={onClose} title={'Utwórz nową domenę'}>
        <CreateUserForm onClose={onClose} />
      </DrawerFormWrapper>
    </>
  );
};
