import { Heading, Stack, StackProps } from '@chakra-ui/react';
import React from 'react';

export const DrawerFormSection = ({
  title,
  children,
  ...props
}: StackProps & { title: string }) => {
  return (
    <Stack w={'full'} spacing={4} {...props}>
      <Heading size={'sm'}>{title}</Heading>
      {children}
    </Stack>
  );
};
