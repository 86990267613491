import { Center } from '@chakra-ui/react';
import { Outlet } from 'react-router-dom';

import { authRoutes } from '@/features/auth';

const PuiblicWrapper = () => {
  return (
    <Center minH={`100vh`}>
      <Outlet />
    </Center>
  );
};

export const publicRoutes = [
  {
    path: '',
    element: <PuiblicWrapper />,
    children: authRoutes,
  },
];
