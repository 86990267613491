import {
  Drawer,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  DrawerProps,
} from '@chakra-ui/react';
import React from 'react';

type Props = DrawerProps & {
  title: string;
};

export const DrawerFormWrapper = ({ children, title, ...props }: Props) => {
  return props.isOpen ? (
    <Drawer {...props} size={'lg'}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>{title}</DrawerHeader>
        {children}
      </DrawerContent>
    </Drawer>
  ) : null;
};
