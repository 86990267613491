import CreatePasswordPage from './CreatePasswordPage';
import LoginPage from './LoginPage';

export const authRoutes = [
  {
    path: '',
    element: <LoginPage />,
  },
  {
    path: 'create-password',
    element: <CreatePasswordPage />,
  },
];
