import React from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import FormInput from '@/components/form/FormInput';
import { FormReactSelect } from '@/components/form/FormReactSelect';
import { DrawerForm } from '@/components/ui/Drawer/DrawerForm';
import { DrawerFormSection } from '@/components/ui/Drawer/DrawerFormSection';
import { Domain, useDomainsQuery } from '@/features/domains';

import { useCreateTenantMutation } from '../../api';

type Form = {
  domain: string;
  name: string;
  email: string;
  password: string;
  repeatPassword: string;
  assignedDomains: Array<Domain>;
};

type Props = {
  onClose: () => void;
};

export const CreateTenantForm = ({ onClose }: Props) => {
  const {
    register,
    formState: { isSubmitting },
    control,
    handleSubmit,
  } = useForm<Form>({
    defaultValues: {
      name: '',
      assignedDomains: [],
    },
  });

  const { data: domains, isLoading } = useDomainsQuery();

  const [createTenant] = useCreateTenantMutation();

  const handleSubmitForm = async (values: Form) => {
    try {
      console.log(values);
      const body = {
        name: values.name,
        relatedDomains: values.assignedDomains.map((d) => d.domain),
      };
      await createTenant({ body }).unwrap();
      toast.done(`Pomyślnie utworzono domenę ${values.name}`);
      onClose();
    } catch (err: any) {
      toast.error(err.data.message);
    }
  };

  return (
    <DrawerForm
      formId="create-domain-form"
      onClose={onClose}
      isSubmitting={isSubmitting}
      submitLabel="Utwórz"
      onSubmit={handleSubmit(handleSubmitForm)}
    >
      <DrawerFormSection title={'Domena'}>
        <FormInput {...register('name')} placeholder={'Nazwa instancji'} />
      </DrawerFormSection>
      <DrawerFormSection title={'Przypisane placówki'}>
        <FormReactSelect
          control={control}
          name="assignedDomains"
          options={domains || []}
          isLoading={isLoading}
          getOptionLabel={(option) => option.name}
          getOptionValue={(option) => option.domain}
          placeholder={'Wybierz placówki...'}
          isMulti
        />
      </DrawerFormSection>
    </DrawerForm>
  );
};
