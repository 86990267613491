import { Flex, HStack, Square, Stack, Text } from '@chakra-ui/react';
import React from 'react';
import { MdHome, MdPerson, MdDomain, MdGroups } from 'react-icons/md';

import { CurrentUserMenu } from '@/features/auth';

import DrawerMenuItem from './DrawerMenuItem';

export const DRAWER_WIDTH = '360px';

const DrawerMenu = () => {
  return (
    <Stack as={'nav'} w={DRAWER_WIDTH} shadow={'md'} h={`100vh`}>
      <Stack
        direction={'column'}
        h={'100%'}
        overflowY={'auto'}
        bg={'purple.900'}
        color={'white'}
        p={2}
        spacing={4}
      >
        <HStack justify={'center'} p={4}>
          <Square p={2} borderRadius="md" bg="whiteAlpha.100">
            <MdGroups size={32} />
          </Square>
          <Stack justifyItems={'center'}>
            <Text fontWeight={'bold'} textAlign={'center'}>
              4P Management
            </Text>
            <Text fontWeight={'normal'} fontSize={'sm'} textAlign={'center'}>
              Admin
            </Text>
          </Stack>
        </HStack>

        {/* <Divider /> */}
        <DrawerMenuItem to={'/app'} label={'Start'} icon={<MdHome />} matchString={'/app'} />
        <DrawerMenuItem to={'/app/users'} label={'Użytkownicy'} icon={<MdPerson />} />
        <DrawerMenuItem
          to={'/app/instances'}
          label={'Instancje zarządzające'}
          icon={<MdDomain />}
        />
        <Flex flexGrow={1} />
        <Flex justify={'stretch'}>
          <CurrentUserMenu />
        </Flex>
        <Text fontSize={'xs'} color={'gray.300'} align="center">
          UI v0.1.0
        </Text>
      </Stack>
    </Stack>
  );
};

export default DrawerMenu;
