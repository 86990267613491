import { useCallback } from 'react';

import { useMeQuery } from '@/features/users';
import { useAppDispatch, useAppSelector } from '@/hooks/store';

import { logout } from '../stores/authSlice';

export const useAuth = () => {
  const { isAuth } = useAppSelector((state) => state.auth);
  const { data: currentUser } = useMeQuery();
  const dispatch = useAppDispatch();

  const handleLogout = useCallback(() => {
    localStorage.removeItem('access_token');
    dispatch(logout());
  }, [dispatch]);

  return { isAuth, logout: handleLogout, currentUser };
};
