import { Flex, IconButton, Menu, MenuButton, MenuList, MenuListProps } from '@chakra-ui/react';
import React from 'react';
import { MdMoreVert } from 'react-icons/md';

export const TableActionMenu = (props: MenuListProps) => {
  return (
    <Flex justify={'end'}>
      <Menu>
        <MenuButton as={IconButton} icon={<MdMoreVert size={'20'} />} variant={'ghost'} />
        <MenuList {...props} />
      </Menu>
    </Flex>
  );
};
