import { Heading, Stack } from '@chakra-ui/react';
import React from 'react';

import { ReactComponent as WipIlustration } from '@/assets/WIP.svg';

type Props = {
  title?: string;
};

export const WipPage = ({ title }: Props) => {
  return (
    <Stack h={'100%'} justify="center" align={'center'}>
      {title ? (
        <Heading size={'md'} textAlign={'center'}>
          {title}...
        </Heading>
      ) : null}
      <Heading textAlign={'center'}> Pracujemy nad tym!</Heading>
      <WipIlustration height={'600px'} />
    </Stack>
  );
};
