import { Button, DrawerBody, DrawerFooter, HStack, Stack } from '@chakra-ui/react';
import React, { ReactNode } from 'react';

import { DrawerFormSkeleton } from './DrawerFormSkeleton';

type Props = {
  formId: string;
  onClose: () => void;
  onSubmit: React.FormEventHandler;
  isLoading?: boolean;
  isSubmitting?: boolean;
  submitLabel?: string;
  children: ReactNode;
};

export const DrawerForm = ({
  children,
  formId,
  onClose,
  isLoading,
  isSubmitting,
  submitLabel,
  onSubmit,
}: Props) => {
  if (isLoading) return <DrawerFormSkeleton onClose={onClose} />;

  return (
    <>
      <DrawerBody>
        <Stack id={formId} as={'form'} align={'flex-start'} spacing={8} onSubmit={onSubmit}>
          {children}
        </Stack>
      </DrawerBody>
      <DrawerFooter>
        <HStack>
          <Button disabled={isSubmitting} size={'lg'} onClick={onClose}>
            Anuluj
          </Button>
          <Button
            type={'submit'}
            form={formId}
            colorScheme={'purple'}
            isLoading={isSubmitting}
            size={'lg'}
          >
            {submitLabel || 'Uwtórz'}
          </Button>
        </HStack>
      </DrawerFooter>
    </>
  );
};
