import { Center, Flex } from '@chakra-ui/react';
import React from 'react';

import { DomainsEmptyState } from '@/components/ui/Placeholders/DomainsEmptyState';
import { Table } from '@/components/ui/Table';

import { useTenantsQuery } from '../../api/tenantsApi';

import { columns } from './Columns';

export const DomainsTable = () => {
  const { data, isLoading, currentData } = useTenantsQuery();

  if (!isLoading && currentData?.length === 0)
    return (
      <Flex flexGrow={1} direction="column">
        <Center h={'100%'}>
          <DomainsEmptyState
            butonTitle="Utwórz nową domenę"
            onClick={() => console.log('Dodawanie domeny')}
            emptyStateMessage="Brak istniejących domen"
          />
        </Center>
      </Flex>
    );

  return <Table data={data || []} columns={columns} isLoading={isLoading} />;
};
