import { createColumnHelper } from '@tanstack/react-table';

import { TableActionMenu } from '@/components/ui/Table/TableActionMenu';

import { User } from '../../types';
import { DeleteUser } from '../DeleteUser/DeleteUser';
import { EditUser } from '../EditUser/EditUser';

const columnHelper = createColumnHelper<User>();

export const columns = [
  columnHelper.accessor('email', {
    header: 'Email',
  }),
  columnHelper.accessor('name', {
    header: 'Imię i nazwisko',
  }),
  columnHelper.accessor('role', {
    header: 'Rola',
  }),
  columnHelper.display({
    id: 'actions',
    cell: ({ row }) => {
      return (
        <TableActionMenu>
          <EditUser userId={row.original.id} />
          <DeleteUser userId={row.original.id} />
        </TableActionMenu>
      );
    },
  }),
];
