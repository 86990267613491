import { Button, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react';
import React from 'react';
import { MdSettings } from 'react-icons/md';

import { useAuth } from '../hooks';

export const CurrentUserMenu = () => {
  const { isAuth, logout, currentUser } = useAuth();

  const handleLogout = () => {
    logout();
  };

  return isAuth ? (
    <Menu placement="top-start">
      <MenuButton as={Button} width={'100%'} colorScheme={'purple'} rightIcon={<MdSettings />}>
        {currentUser?.user.name}
      </MenuButton>
      <MenuList color={'initial'} background={'whiteAlpha.700'}>
        <MenuItem onClick={handleLogout}>Wyloguj</MenuItem>
      </MenuList>
    </Menu>
  ) : null;
};
