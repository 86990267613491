import { TableColumnHeaderProps, Th as TableColumnHeader } from '@chakra-ui/react';
import React from 'react';

export const Th = (props: TableColumnHeaderProps) => {
  return (
    <TableColumnHeader
      _first={{ borderTopLeftRadius: 'md', borderBottomLeftRadius: 'md' }}
      _last={{ borderTopRightRadius: 'md', borderBottomRightRadius: 'md' }}
      {...props}
    />
  );
};
