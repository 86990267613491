import { TableContainer, Thead, Table as ChakraTable, Tbody } from '@chakra-ui/react';
import { ColumnDef, flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import React from 'react';

import { SkeletonTd } from './SkeletonTd';
import { Td } from './Td';
import { Th } from './Th';
import { Tr } from './Tr';

type Props<T> = {
  data: Array<T>;
  columns: ColumnDef<T, any>[];
  isLoading: boolean;
};

export const Table = <T extends object>({ data, columns, isLoading }: Props<T>) => {
  const table = useReactTable({
    data: data || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <TableContainer>
      <ChakraTable variant={'unstyled'} sx={{ borderCollapse: 'separate', borderSpacing: '0 8px' }}>
        <Thead fontSize={'md'}>
          {table.getHeaderGroups().map((hg) => (
            <Tr key={hg.id}>
              {hg.headers.map((header) => (
                <Th key={header.id}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(header.column.columnDef.header, header.getContext())}
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody>
          {!isLoading
            ? table.getRowModel().rows.map((row) => (
                <Tr key={row.id}>
                  {row.getVisibleCells().map((cell) => (
                    <Td key={cell.id}>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </Td>
                  ))}
                </Tr>
              ))
            : table.getHeaderGroups().map((gh) => (
                <React.Fragment key={gh.id}>
                  <Tr>
                    {gh.headers.map((header) => (
                      <SkeletonTd key={header.id} />
                    ))}
                  </Tr>
                  <Tr>
                    {gh.headers.map((header) => (
                      <SkeletonTd key={header.id} />
                    ))}
                  </Tr>
                  <Tr>
                    {gh.headers.map((header) => (
                      <SkeletonTd key={header.id} />
                    ))}
                  </Tr>
                </React.Fragment>
              ))}
        </Tbody>
      </ChakraTable>
    </TableContainer>
  );
};
